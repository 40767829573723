import React, { useState } from 'react';
import styles from './PokerTable.module.css';
import { useGameState } from '../../hooks/useGameState';
import PlayerSeat from '../PlayerSeat/PlayerSeat';
import PokerControls from '../PokerControls/PokerControls';
// import ChipsStack from '../ChipsStack/ChipsStack';
import CommunityCards from '../CommunityCards/CommunityCards';

const PokerTable = () => {
  const { gameState, handleAction, makeNextStep, generateChips, discardCards } =
    useGameState();
  const [selectedCards, setSelectedCards] = useState([]);

  const handleCardSelect = (index) => {
    setSelectedCards((prev) => {
      if (prev.includes(index)) {
        return prev.filter((i) => i !== index);
      }
      if (prev.length < 2) {
        return [...prev, index];
      }
      return prev;
    });
  };

  const handlePlayerAction = (action, amount) => {
    if (action === 'discard') {
      discardCards(5, amount); // 5 is the main player index
      setSelectedCards([]);
    } else {
      handleAction(action, amount);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.pokerTable}>
        <CommunityCards
          communityCards={gameState.communityCards}
          pot={gameState.pot}
          generateChips={generateChips}
        />

        {gameState.positions.map((seatId, index) => (
          <PlayerSeat
            key={seatId}
            id={seatId}
            player={gameState.players[index]}
            isActive={gameState.activePlayer === index}
            isDealer={gameState.dealerPosition === index}
            position={index}
            holeCards={index === 5 ? gameState.holeCards : null}
            generateChips={generateChips}
            onCardSelect={
              index === 5 && gameState.discardPhase ? handleCardSelect : null
            }
            selectedCards={index === 5 ? selectedCards : []}
          />
        ))}
      </div>

      {gameState.activePlayer === 5 && (
        <PokerControls
          currentBet={gameState.currentBet}
          playerBet={gameState.players[5].bet}
          playerStack={gameState.players[5].stack}
          onAction={handlePlayerAction}
          canCheck={gameState.canCheck}
          isDiscardPhase={gameState.discardPhase}
          selectedCards={selectedCards}
          onSelectCard={handleCardSelect}
        />
      )}

      {gameState.waitingForNextStep && gameState.activePlayer !== 5 && (
        <button className={styles.nextStepButton} onClick={makeNextStep}>
          Next Step
        </button>
      )}
    </div>
  );
};

export default PokerTable;
