import React, { useState, useRef, useEffect } from 'react';
import styles from './PokerControls.module.css';

const PokerControls = ({
  currentBet,
  playerBet,
  playerStack,
  onAction,
  canCheck,
  isDiscardPhase,
  selectedCards,
  onSelectCard,
}) => {
  const [sliderValue, setSliderValue] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const sliderRef = useRef(null);

  const callAmount = currentBet - playerBet;
  const minRaise = Math.max(currentBet * 2, currentBet + 2);
  const effectiveMinRaise = Math.max(minRaise - playerBet, 0);
  const maxRaise = playerStack + playerBet;

  const calculateRaiseAmount = (value) => {
    const raiseRange = maxRaise - effectiveMinRaise;
    return Math.floor(effectiveMinRaise + raiseRange * (value / 100));
  };

  const handleSliderChange = (e) => {
    const value = parseInt(e.target.value);
    setSliderValue(value);
  };

  const handleSliderInteraction = (clientX) => {
    const rect = sliderRef.current.getBoundingClientRect();
    let position = (clientX - rect.left) / rect.width;
    position = Math.max(0, Math.min(position, 1));
    setSliderValue(Math.round(position * 100));
  };

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (isDragging) {
        handleSliderInteraction(e.clientX);
      }
    };

    const handleMouseUp = () => {
      setIsDragging(false);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging]);

  const raiseAmount = calculateRaiseAmount(sliderValue);

  if (isDiscardPhase) {
    return (
      <div className={styles.container}>
        <div className={styles.controls}>
          <button
            className={styles.discard}
            onClick={() => onAction('discard', selectedCards)}
          >
            Discard Selected ({selectedCards.length})
          </button>
          <button
            className={styles.skip}
            onClick={() => onAction('discard', [])}
          >
            Skip Discard
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.controls}>
        <button
          className={styles.fold}
          onClick={() => onAction('fold')}
          disabled={canCheck}
        >
          FOLD
        </button>
        {canCheck ? (
          <button className={styles.check} onClick={() => onAction('check')}>
            CHECK
          </button>
        ) : (
          <button
            className={styles.call}
            onClick={() => onAction('call')}
            disabled={playerBet === currentBet}
          >
            CALL ${callAmount}
          </button>
        )}
        <button
          className={styles.raise}
          onClick={() => onAction('raise', raiseAmount)}
          disabled={playerStack < effectiveMinRaise}
        >
          RAISE
        </button>
      </div>

      <div className={styles.raiseControls}>
        <div className={styles.sliderContainer} ref={sliderRef}>
          <input
            type='range'
            className={styles.raiseSlider}
            min='0'
            max='100'
            value={sliderValue}
            onChange={handleSliderChange}
            disabled={playerStack < effectiveMinRaise}
            onMouseDown={() => setIsDragging(true)}
          />
          <div className={styles.sliderTrack} />
          <div
            className={styles.sliderThumb}
            style={{
              left: `${sliderValue}%`,
            }}
          >
            ${raiseAmount}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PokerControls;