// src/components/ChipsStack.js
import React from 'react';
import styles from './ChipsStack.module.css';

const ChipsStack = ({ amount, generateChips, containerClassName }) => {
  if (!amount) return null;

  const chips = generateChips(amount);

  return (
    <div className={`${styles.chipsContainer} ${containerClassName}`}>
      <div
        className={styles.bet}
        style={{ display: amount > 0 ? 'block' : 'none' }}
      >
        ${amount}
      </div>
      {chips.map((chip, index) => (
        <div
          key={index}
          className={`${styles.chip} ${styles[`chip${chip}`]}`}
          style={{
            transform: `translateY(${index * -5}px)`,
            zIndex: chips.length - index,
          }}
        >
          ${chip}
        </div>
      ))}
    </div>
  );
};

export default ChipsStack;
