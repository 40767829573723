// src/components/CommunityCards.js
import React from 'react';
import styles from './CommunityCards.module.css';
import ChipsStack from '../ChipsStack/ChipsStack';

const CommunityCards = ({ communityCards, pot, generateChips }) => {
  return (
    <div className={styles.communityCards}>
      <div className={styles.pot}>
        <div className={styles.potChipsContainer}>
          <ChipsStack amount={pot} generateChips={generateChips} />
        </div>
      </div>
      <div className={styles.cardsContainer}>
        {communityCards.map((card, index) => (
          <div
            key={index}
            className={`${styles.card} ${
              card.endsWith('♥') || card.endsWith('♦')
                ? styles.hearts
                : styles.spades
            }`}
          >
            {card}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CommunityCards;
