import {useState, useCallback, useEffect} from 'react';

const SMALL_BLIND = 1;
const BIG_BLIND = 2;
const DELAYS = {
    ACTION: 1000,      // Задержка между действиями
    STREET: 1500,      // Задержка для следующей улицы
    HAND: 2000        // Задержка для новой раздачи
};

const initialState = {
    pot: 0,
    currentBet: 0,
    round: 'preflop',
    bettingRound: 0,
    positions: ['seat1', 'seat2', 'seat3', 'seat4', 'seat5', 'main-player'],
    activePlayer: 0,
    dealerPosition: 0,
    lastRaisePlayer: -1,
    communityCards: [],
    holeCards: [],
    waitingForNextStep: false,
    players: Array(6).fill().map(() => ({
        stack: 100,
        bet: 0,
        folded: false,
        hasActed: false
    }))
};

export function useGameState() {
    const [gameState, setGameState] = useState(initialState);

    // Базовые функции остаются без изменений
    const generateChips = useCallback((amount) => {
        const chips = [];
        const denominations = [100, 25, 10, 5, 1];

        for (let denom of denominations) {
            const count = Math.floor(amount / denom);
            for (let i = 0; i < count; i++) {
                chips.push(denom);
            }
            amount %= denom;
        }

        return chips;
    }, []);

    const dealCards = useCallback((count) => {
        const suits = ['♠', '♥', '♦', '♣'];
        const values = ['2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K', 'A'];
        const cards = [];
        for (let i = 0; i < count; i++) {
            cards.push(values[Math.floor(Math.random() * values.length)] + suits[Math.floor(Math.random() * suits.length)]);
        }
        return cards;
    }, []);

    const dealHoleCards = useCallback(() => {
        return dealCards(2);
    }, [dealCards]);

    const isRoundComplete = useCallback((state) => {
        const activePlayers = state.players.filter(p => !p.folded);
        const allBetsEqual = activePlayers.every(p => p.bet === state.currentBet || p.stack === 0);
        const allPlayersActed = activePlayers.every(p => p.hasActed);
        return allBetsEqual && allPlayersActed;
    }, []);

    const countActivePlayers = useCallback((state) => {
        return state.players.filter(p => !p.folded).length;
    }, []);

    // Добавляем анимацию фишек
    const animateChipsToCenter = useCallback(() => {
        return new Promise(resolve => {
            setTimeout(resolve, DELAYS.ACTION);
        });
    }, []);

    const nextStreet = useCallback(async () => {
        await animateChipsToCenter();
        
        setGameState(prev => {
            const newState = {...prev};
            newState.bettingRound++;
            newState.currentBet = 0;
            newState.lastRaisePlayer = -1;
            newState.players = newState.players.map(player => ({
                ...player,
                bet: 0,
                hasActed: false
            }));

            if (newState.round === 'preflop') {
                newState.round = 'flop';
                newState.communityCards = dealCards(3);
            } else if (newState.round === 'flop') {
                newState.round = 'turn';
                newState.communityCards = [...newState.communityCards, ...dealCards(1)];
            } else if (newState.round === 'turn') {
                newState.round = 'river';
                newState.communityCards = [...newState.communityCards, ...dealCards(1)];
            }

            newState.activePlayer = newState.dealerPosition;
            do {
                newState.activePlayer = (newState.activePlayer + 1) % 6;
            } while (newState.players[newState.activePlayer].folded);

            newState.waitingForNextStep = true;
            return newState;
        });
    }, [dealCards, animateChipsToCenter]);

    const startNewHand = useCallback(() => {
        setGameState(prev => {
            const newState = {
                ...initialState,
                dealerPosition: (prev.dealerPosition + 1) % 6,
                pot: SMALL_BLIND + BIG_BLIND,
                currentBet: BIG_BLIND,
                waitingForNextStep: true,
                players: Array(6).fill().map(() => ({
                    stack: 100,
                    bet: 0,
                    folded: false,
                    hasActed: false
                }))
            };

            const sbPosition = (newState.dealerPosition + 1) % 6;
            const bbPosition = (newState.dealerPosition + 2) % 6;

            newState.players[sbPosition].stack -= SMALL_BLIND;
            newState.players[sbPosition].bet = SMALL_BLIND;

            newState.players[bbPosition].stack -= BIG_BLIND;
            newState.players[bbPosition].bet = BIG_BLIND;

            newState.activePlayer = (bbPosition + 1) % 6;
            newState.holeCards = dealHoleCards();

            return newState;
        });
    }, [dealHoleCards]);

    const makeAIAction = useCallback(() => {
        return new Promise(resolve => {
            setTimeout(() => {
                setGameState(prev => {
                    const newState = {...prev};
                    const player = newState.players[newState.activePlayer];
                    const callAmount = newState.currentBet - player.bet;

                    if (Math.random() < 0.1) {
                        player.folded = true;
                    } else if (player.stack <= callAmount || Math.random() < 0.7) {
                        if (player.stack <= callAmount) {
                            newState.pot += player.stack;
                            player.bet += player.stack;
                            player.stack = 0;
                        } else {
                            newState.pot += callAmount;
                            player.stack -= callAmount;
                            player.bet = newState.currentBet;
                        }
                    } else {
                        const raiseAmount = Math.min(player.stack, newState.currentBet * 2);
                        newState.pot += raiseAmount - player.bet;
                        player.stack -= raiseAmount - player.bet;
                        player.bet = raiseAmount;
                        newState.currentBet = raiseAmount;
                        newState.lastRaisePlayer = newState.activePlayer;
                    }

                    player.hasActed = true;

                    if (isRoundComplete(newState)) {
                        if (newState.round === 'river' || countActivePlayers(newState) === 1) {
                            return {
                                ...newState,
                                waitingForNextStep: true,
                                needsNewHand: true
                            };
                        } else {
                            return {
                                ...newState,
                                waitingForNextStep: true,
                                needsNextStreet: true
                            };
                        }
                    } else {
                        do {
                            newState.activePlayer = (newState.activePlayer + 1) % 6;
                        } while (newState.players[newState.activePlayer].folded);

                        newState.waitingForNextStep = true;
                    }

                    resolve();
                    return newState;
                });
            }, DELAYS.ACTION);
        });
    }, [isRoundComplete, countActivePlayers]);

    const handleAction = useCallback((action, amount = 0) => {
        if (gameState.activePlayer !== 5) return;

        setGameState(prev => {
            const newState = {...prev};
            const player = newState.players[5];

            switch (action) {
                case 'fold':
                    player.folded = true;
                    break;

                case 'call': {
                    const callAmount = newState.currentBet - player.bet;
                    if (callAmount > player.stack) {
                        newState.pot += player.stack;
                        player.bet += player.stack;
                        player.stack = 0;
                    } else {
                        player.stack -= callAmount;
                        newState.pot += callAmount;
                        player.bet = newState.currentBet;
                    }
                    break;
                }

                case 'raise': {
                    const totalBet = player.bet + amount;
                    if (amount <= player.stack) {
                        player.stack -= amount;
                        newState.pot += amount;
                        player.bet = totalBet;
                        newState.currentBet = totalBet;
                        newState.lastRaisePlayer = 5;
                    }
                    break;
                }

                default:
                    // Handle unexpected action
                    console.warn(`Unexpected action: ${action}`);
                    break;
            }

            player.hasActed = true;

            if (isRoundComplete(newState)) {
                if (newState.round === 'river' || countActivePlayers(newState) === 1) {
                    return {
                        ...newState,
                        waitingForNextStep: true,
                        needsNewHand: true
                    };
                } else {
                    return {
                        ...newState,
                        waitingForNextStep: true,
                        needsNextStreet: true
                    };
                }
            } else {
                do {
                    newState.activePlayer = (newState.activePlayer + 1) % 6;
                } while (newState.players[newState.activePlayer].folded);

                newState.waitingForNextStep = true;
            }

            return newState;
        });
    }, [gameState.activePlayer, isRoundComplete, countActivePlayers]);

    const makeNextStep = useCallback(async () => {
        setGameState(prev => {
            if (prev.needsNewHand) {
                setTimeout(() => startNewHand(), DELAYS.HAND);
                return prev;
            }
            
            if (prev.needsNextStreet) {
                setTimeout(() => nextStreet(), DELAYS.STREET);
                return prev;
            }

            if (prev.waitingForNextStep && prev.activePlayer !== 5) {
                return {
                    ...prev,
                    waitingForNextStep: false
                };
            }

            return prev;
        });

        // Если это ход AI, выполняем его после задержки
        setGameState(prev => {
            if (!prev.waitingForNextStep && prev.activePlayer !== 5) {
                makeAIAction();
            }
            return prev;
        });
    }, [startNewHand, nextStreet, makeAIAction]);

    useEffect(() => {
        startNewHand();
    }, [startNewHand]);

    return {
        gameState,
        generateChips,
        handleAction,
        makeNextStep,
        startNewHand
    };
}