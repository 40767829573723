// PlayerSeat.js
import React from 'react';
import styles from './PlayerSeat.module.css';

const PlayerSeat = ({
  id,
  player,
  isActive,
  isDealer,
  position,
  holeCards,
  generateChips,
}) => {
  const getImageUrl = (pos) => {
    if (pos === 5) {
      return `https://randomuser.me/api/portraits/women/1.jpg`;
    }
    return `https://randomuser.me/api/portraits/men/${pos + 1}.jpg`;
  };

  const getSeatPositionClass = () => {
    if (position === 5) return styles.mainPlayer;
    return styles[`seat${position + 1}`];
  };

  return (
    <div
      className={`
            ${styles.seat} 
            ${getSeatPositionClass()}
            ${isActive && position !== 5 ? styles.active : ''} 
            ${player.folded ? styles.folded : ''}
        `}
    >
      <span className={styles.position}>{position === 5 ? 'You' : ''}</span>
      <img
        src={getImageUrl(position)}
        alt={`Player ${position + 1}`}
        className={styles.playerImage}
      />

      <div className={styles.stack}>${player.stack}</div>

      <div className={styles.chipsContainer}>
        <div
          className={styles.bet}
          style={{ display: player.bet > 0 ? 'block' : 'none' }}
        >
          ${player.bet}
        </div>
        {player.bet > 0 &&
          generateChips(player.bet).map((chip, index) => (
            <div
              key={index}
              className={`${styles.chip} ${styles[`chip${chip}`]}`}
              style={{
                transform: `translateY(${index * -5}px)`,
                zIndex: 100 - index,
              }}
            >
              ${chip}
            </div>
          ))}
      </div>

      {position !== 5 && (
        <div className={styles.holeCardsMini}>
          <div className={styles.holeCardMini} />
          <div className={styles.holeCardMini} />
        </div>
      )}

      {position === 5 && holeCards && (
        <div className={styles.holeCards}>
          <div className={styles.cardsContainer}>
            {holeCards.map((card, index) => (
              <div
                key={index}
                className={`
                  ${styles.card} 
                  ${
                    card.endsWith('♥') || card.endsWith('♦')
                    ? styles.hearts
                    : styles.spades
                  }
                `}
              >
                {card}
              </div>
            ))}
          </div>
        </div>
      )}

      {isDealer && <div className={styles.dealerButton}>D</div>}
    </div>
  );
};

export default PlayerSeat;
