// src/App.js
import React, { useEffect } from 'react';
import PokerTable from './components/PokerTable/PokerTable';

function App() {
  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      const webapp = window.Telegram.WebApp;
      webapp.ready();
      webapp.expand();
      webapp.disableVerticalSwipes();
      webapp.setHeaderColor('#27ae60');
    }
  }, []);

  return <PokerTable />;
}

export default App;
